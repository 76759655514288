

export default {
/*  bind (el, binding) {
  console.log(el,binding,'preload')
  let img = new Image();
  img.src = binding.value;
  img.onload = function () {
   el.src = binding.value; 
  }

 }, */
 mounted(el, binding) {
  let img = new Image();
  img.src = binding.value;
  img.onload = function () {
   el.src = binding.value; 
  }

 },
}