<template>
  <div class="login-page">
    <header>
      <!--  <div class="logo"><img src="../../images/logo.png"></div> -->
      <!-- <return-back url="unpermission"></return-back> -->
      <div class="icon-content">
        <span class="icon icon-right text23d-icon icon-guanbi" @click="$emit('close')"></span>
      </div>
    </header>
    <div class="login">
      <div v-if="isUserOrPasswordError" class="error">
        <div class="error-content">
          <span class="icon">!</span>
          <span class="text">{{ userOrpasswordError }}</span>

        </div>
      </div>
      <div class="login-wrapper">

        <div class="logo login-item"><img src="../../images/t3d-login.png"></div>
        <div class="title login-item">Nüwa</div>
        <div class="username login-item">
          <div class="input-box">
            <input type="text" v-model="username" placeholder="Account" @input="hideUsernameError"
              @focus="setUsernameBoxShadow" @blur="removeUsernameBoxShadow">

          </div>

          <!-- <div v-if="isUsernameError" class="error">
              <span class="icon">!</span>
              <span class="text">{{usernameError}}</span>
            </div> -->
        </div>
        <div class="userpwd login-item">
          <div class="input-box">
            <input type="password" v-model="password" @input="hidePasswordError" @focus="setPasswordBoxShadow"
              @blur="removePasswordBoxShadow" placeholder="Invitation Code">
          </div>
          <!-- div v-if="isPasswordError" class="error">
            <span class="icon">!</span>
            <span class="text">{{passwordError}}</span>
          </div>
          <div v-if="isUserOrPasswordError" class="error">
            <span class="icon">!</span>
            <span class="text">{{userOrpasswordError}}</span>
          </div> -->
        </div>
        <!-- <div class="btn login-item" >Login in</div> -->
        <Button class="login-item btn-content btn-primary" @click="login"> <span class="text">Log in</span></Button>



      </div>
      <!-- <p class="illustration-tip">Shenzhen lightillusions Technology Co., Ltd.</p> -->
      <div class="more-content">
        <input type="checkbox" class="checkbox" v-model="checkedPrivacy">
        <span> 我已阅读<a class="link" href="/protocol" target="_blank">《用户协议》</a>和 <a class="link" href="/privacy"
            target="_blank">《隐私政策》</a></span>
      </div>


    </div>

  </div>
</template>

<script setup>
import { ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import * as api from "@api"
import { setCookie, setStorage } from "../../utils";
import ReturnBack from "@/components/returnback.vue"
import Button from '@/components/button.vue'
const store = useStore();
const router = useRouter();
const props = defineProps({
  origin: String
});
// 向父组件传递的事件
const emits = defineEmits(["close"]);


let username = ref("");
let password = ref("");
const checkedPrivacy = ref(false);
/* let usernameError = ref("账号不能为空");
let passwordError = ref("密码不能为空"); */
let userOrpasswordError = ref("账号或密码错误");
let isUsernameError = ref(false);
let isPasswordError = ref(false);
let isUserOrPasswordError = ref(false);
let isUsernameHasShadow = ref(false);
let isPasswordHasShadow = ref(false);




function handlePrivacyPolicy () {
  router.push({ name: "privacy" })

}
function handleUserProtocol () {
  router.push({ name: "protocal" })

}
function checkUsername () {
  return isUsernameError.value = username.value == "";
}

function checkPassword () {
  return isPasswordError.value = password.value == "";
}

function setUsernameBoxShadow () {
  isUsernameHasShadow.value = true;
}
function removeUsernameBoxShadow () {
  isUsernameHasShadow.value = false;
}

function setPasswordBoxShadow () {
  console.log('setPasswordBoxShadow')
  isPasswordHasShadow.value = true;
}

function removePasswordBoxShadow () {
  isPasswordHasShadow.value = false;
}

function hideUsernameError () {
  isUsernameError.value = false;
  isUserOrPasswordError.value = false;
}

function hidePasswordError () {
  isPasswordError.value = false;
  isUserOrPasswordError.value = false;
}


async function login () {
  if (username.value == "" || password.value == "" || checkedPrivacy.value == false) {

    let usernameStr = username.value == "" && "Please enter account";
    let passwordStr = password.value == "" && "Please enter invitation code";
    console.log("=", checkedPrivacy.value)
    let checkedPrivacyStr = checkedPrivacy.value == false && "Please checked Privicy";
    userOrpasswordError.value = usernameStr || passwordStr || checkedPrivacyStr;
    isUserOrPasswordError.value = true;
    return;
  }
  try {
    let res = await api.login(username.value, password.value);

    if (res.status == 0 && res.data?.access_token) {
      console.log('res login...')
      setCookie("access_token", res.data.access_token, "/");
      setStorage("username", username.value);
      setStorage("__userInfo", JSON.stringify(res.data))
      store.commit('setIsTokenValid', true);
      emits('close');
      if (props.origin == 'share') {
        router.push({ name: "list" })
      }


      // router.push({ name: "list" })
    } else {
      isUserOrPasswordError.value = true;
      userOrpasswordError.value = "Account or code error";
    }
  } catch (e) {
    isUserOrPasswordError.value = true;
    userOrpasswordError.value = "Account or code error";
  }
}


</script>

<style lang="less" scoped>
.login-page {
  position: fixed;
  top: 0;
  z-index: 12;

  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  flex-direction: column;

  /*  background-image: url(../../images/background_login.png);
  background-size: cover; */
  // background: rgba(0,0,0);
  backdrop-filter: blur(50px);

  header {
    position: absolute;
    right: 30px;
    top: 30px;

    .icon-content {
      border-radius: 50%;
      width: 44px;
      height: 44px;
      text-align: center;
      line-height: 44px;

      &:hover {
        background-color: rgba(51, 51, 51, 0.44);
        backdrop-filter: blur(30px);


      }



    }

    .icon-guanbi {
      color: #fff;
      font-size: 24px;

      &:hover {
        background-color: rgba();
      }
    }


    .logo {
      width: 244px;
      height: 42px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .login {
    position: relative;




    .login-wrapper {

      position: relative;
      padding: 24px;
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 320px;
      height: 354px;
      box-sizing: border-box;
      backdrop-filter: blur(20px);
      background-image: url('../../images/login-border.png');
      background-size: cover;

      font-family: PingFangSC-Semibold;
      background-color: rgba(149, 149, 149, 0.25);
      border-radius: 22.4px;

    }



    .title {
      height: 45px;
      font-size: 32px;
      color: #FFFFFF;
      letter-spacing: 0;
      text-align: center;
      font-weight: 600;
    }

    .login-item {
      margin-bottom: 12px;


    }

    .btn-content {
      width: 240px;
      height: 40px;

    }

    img {
      width: 80px;
      height: 80px;
    }

    .input-box {
      background: rgba(0, 0, 0, 0.9);
      box-shadow: inset 1px 1px 3px 0px rgba(0, 0, 0, 0.1), inset 0px 0px 1px 0px rgba(255, 255, 255, 0.3);
      border-radius: 20px;
      width: 240px;
      height: 40px;

      // background-image: linear-gradient(to bottom right,hsla(0,0%,100%,0.2), hsla(0,0%,57%,0.26), hsla(0,0%,33%,1));

      overflow: hidden;

      input {
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        padding: 11px 20px;
        border: none;
        background: rgba(34, 36, 35, 0.60);
        line-height: 28px;
        color: #EBEBEB;
        font-size: 14px;

        &::placeholder {
          color: #727272;


        }

        &:focus {

          &::placeholder {
            // color: #424344;
            opacity: 0.5;


          }
        }
      }

      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus {
        -webkit-text-fill-color: #EBEBEB;
        -webkit-box-shadow: inset 0 0 0px 100px rgba(0, 0, 0, 0.9);
        caret-color: #EBEBEB;
        border-color: rgba(0, 0, 0, 0.9);


      }

      input:-internal-autofill-selected {
        background-color: transparent !important;
      }

    }

    .input-box-focus {
      background: rgba(34, 36, 35, 0.40);
      border: 1px solid #979797;
      box-shadow: 0 0 13px 0 rgba(255, 255, 255, 0.12);

      input {
        background: rgba(34, 36, 35, 0.40);
      }
    }

    .userpwd,
    .username {
      position: relative;
    }

    .userpwd {
      margin-bottom: 16px;
    }

    .btn {
      position: relative;
      width: 240px;
      height: 40px;
      background: #fff;
      border-radius: 12px;
      color: #000;
      text-align: center;
      line-height: 40px;
      border-radius: 20px;
      font-size: 14px;
      font-weight: 200;
      font-family: PingFangSC-Medium;
      margin-top: 4px;
      cursor: pointer;

      &:hover {
        background-color: #EBEBEB;
        // color: rgba(0, 0, 0, 0.5)
      }
    }

  }

  .error {
    position: absolute;
    top: -60px;


    font-size: 12px;
    color: #ffffff;
    margin-bottom: 20px;
    backdrop-filter: blur(40px);
    left: 49%;
    transform: translateX(-50%);




    .error-content {
      padding: 12px 16px;
      background: rgba(149, 149, 149, 0.25);
      backdrop-filter: blur(20px);
      border-radius: 20px;
      display: flex;
      align-items: center;

      .text {
        font-family: PingFangSC-Regular;
        font-size: 12px;
        color: #FFFFFF;
        letter-spacing: 0;
        line-height: 16px;
        white-space: nowrap;


      }

    }

    .icon {
      display: block;
      width: 14px;
      height: 14px;
      margin-right: 7px;
      background: #EA4335;
      border-radius: 14px;
      text-align: center;
      line-height: 14px;
      font-size: 10px;
      color: #fff;
    }
  }

  .illustration-tip,
  .more-content {
    margin-top: 16px;
    font-family: Manrope-Light;
    font-size: 16px;
    color: #666666;
    letter-spacing: 0;
    text-align: center;
    font-weight: 200;
  }

  .more-content {
    font-size: 12px;
    line-height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;

    .checkbox {
      background: rgba(27, 27, 29, 0.20);
      border: 1px solid #666666;
      position: relative;
      margin-right: 6px;
      border-radius: 50%;
      outline: none;
      -webkit-appearance: none;
      background: transparent;
      cursor: pointer;
      width: 14px;
      box-sizing: border-box;
      height: 14px;

      &:checked {
        border-color: #00B98B;
        color: #fff;
        background-size: cover;
        background-image: url(/src/images/selected.png);
        border: none;
      }


    }

    .link {
      font-family: PingFangSC-Regular;
      font-size: 12px;
      color: #00B98B;
      font-weight: 400;

    }

  }

}
</style>