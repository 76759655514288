import {
 createWebHistory, createWebHashHistory,

 // createRouter as _createRouter ,
 createRouter,
 createMemoryHistory
} from "vue-router";

import routes from "./routes";
import { checkLogin } from "../api";

let router = createRouter({
 // history: createWebHistory(),
 history: import.meta.env.SSR ? createMemoryHistory() : createWebHistory(),
 routes,

});
/* export function createRouter() {
    return _createRouter({
        // history: import.meta.env.SSR ? createMemoryHistory("/ssr") : createWebHistory("/ssr"),
        history: import.meta.env.SSR ? createMemoryHistory() : createWebHistory(),
        routes,
    });
} */

/* router.beforeResolve(async (to, from, next) => {

 if (import.meta.env.SSR) return;
 console.log('router11111', to.name, to)
 let whiteList = ["unpermission", "home", "privacy", "protocol"]
  if (!whiteList.includes(to.name)) {
   try {
    await checkLogin();
   } catch (error) {
    return "/"
   }
  } 
 next();

}) */

export default router;