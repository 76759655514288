<template>
  <button class="common-button" @click="handleClick" :disabled="buttonDisabled || loading" :autofocus="autofocus"
    :type="nativeType" :class="[
    type ? 'btn-' + type : '',
    {
      'is-disabled': buttonDisabled,
      'is-loading': loading,
      'is-plain': plain,
      'is-round': round,
      'is-circle': circle
    }

  ]">
    <i class="el-icon-loading" v-if="loading"></i>
    <i :class="icon" v-if="icon && !loading"></i>
    <span v-if="$slots.default">
      <slot></slot>
    </span>


  </button>
</template>
<script setup>
import { computed } from "vue";
const emits = defineEmits(["click"]);
const props = defineProps({
  type: {
    type: String,
    default: 'default'
  },
  size: String,
  icon: {
    type: String,
    default: ''
  },
  nativeType: {
    type: String,
    default: 'button'
  },
  loading: Boolean,
  disabled: Boolean,
  plain: Boolean,
  autofocus: Boolean,
  round: Boolean,
  circle: Boolean

})
let buttonDisabled = computed(() => {
  return props.disabled
})
const handleClick = (evt) => {
  emits('click', evt);
}
</script>
<style lang="less">
.common-button {
  background: rgba(149, 149, 149, 0.25);
  border-radius: 22px;

  border: none;
  position: relative;
  padding: 5px;
  border-radius: 30px;
  font-family: PingFangSC-Medium;
  font-size: 14px;
  color: #FFFFFF;
  letter-spacing: 0;
  text-align: center;
  font-weight: 500;
  background-clip: border-box;
  background-origin: border-box;
  background-image: linear-gradient(120deg, rgba(76, 216, 181), rgba(26, 206, 161), rgba(0, 185, 139));

  &.btn-normal {
    background-image: linear-gradient(120deg, rgba(161, 161, 161), rgba(91, 91, 91));

    .text {
      background-color: rgb(20, 20, 20);
    }

    &.disabled {
      background-image: linear-gradient(120deg, rgba(45, 45, 45), rgba(31, 31, 31));
      cursor: not-allowed;

      span {
        background-color: #141414;
        color: #5B5B5B;


      }
    }

    &:hover {
      background-image: linear-gradient(120deg, rgba(255, 255, 255), rgba(137, 137, 137));

      span {
        background: #000;
        background-color: #141414;
        color: #fff;
      }
    }

    &:active {
      background-image: linear-gradient(120deg, rgba(90, 90, 90), rgba(55, 55, 55));

      span {
        background-color: #141414;
        color: #fff;
      }
    }


  }


  &:hover {
    background-image: linear-gradient(120deg, rgba(84, 246, 205), rgba(33, 234, 183), rgba(0, 229, 171));

    span {
      background: rgb(0, 229, 171);
    }
  }

  &:active {
    background-image: linear-gradient(120deg, rgba(50, 216, 174), rgba(18, 196, 150), rgba(0, 160, 133));

    span {
      background: rgb(0, 160, 133);
    }

  }

  &.disabled {
    background-image: linear-gradient(120deg, rgba(38, 98, 83), rgba(23, 91, 73), rgba(14, 81, 64));
    cursor: not-allowed;

    span {
      background-color: rgb(14, 81, 64);
      color: rgba(255, 255, 255, 0.4);


    }
  }

  .text {
    z-index: 1;
    position: absolute;
    content: '';
    left: 1.4px;

    /* display: none; */
    top: 1.4px;
    width: calc(100% - 2.8px);
    height: calc(100% - 2.8px);
    box-sizing: border-box;
    border-radius: 30px;
    background: #00B98B;
    text-align: center;
    line-height: 32px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;

  }






}
</style>