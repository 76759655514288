import fetch from "./fetch";
import { getCookie } from "@/utils/index.js";
export function login (username, password, config = {}) {
 return fetch.post(
  "/lime/login",
  {
   username: username,
   password
  },
  config
 );
}

export function checkLogin (config = {}) {
 return fetch.get(
  "/lime/check_login",
  config
 );
}



export function getList ({ currentPage = 0, pageSize = 10, asc, promptAsc, projectType }, config = {}) {
 return fetch.post(
  "/lime/text2model/list_project",
  {
   current_page: currentPage,
   page_size: pageSize,
   asc,
   promptAsc,
   projectType
  },
  {
   ...config,
   alert_error: true
  }
 );
}
/* 
未登录权限页获取数据
*/
export function getLimitList ({ currentPage = 0, pageSize = 10, asc, promptAsc, userToken }, config = {}) {
 return fetch.post(
  "/lime/text2model/list_public_objects",
  {
   current_page: currentPage,
   page_size: pageSize,
   asc,
   promptAsc,
   userToken
  },
  config
 );

 /*  return axios.post(
    "https://lime-nvwa-pre.lightillusions.com/lime/text2model/list_public_objects",
    {
      current_page,
      page_size,
      asc
    },
    config
  ); */
}


export function searchList (data, config = {}) {
 return fetch.get(
  "/lime/text2model/search",
  {
   ...data
  },
  config
 );
 //return axios.get("http://lime-physicapture-pre.lightillusions.com/lime/text2model/search", { ...data })
}

export function createModel (project_name = "", prompt = "一个萝卜", negative_prompt = "", generate_type = "MESH", config = {}) {
 return fetch.post(
  "/lime/text2model/create_project",
  {
   project_name,
   prompt,
   negative_prompt,
   generate_type
  },
  config
 );
}
// 简易版创建模型
export function createSimpleModel (data, config = {}) {
 return fetch.post(
  "/lime/text2model/create_project",
  {
   prompt: data.prompt,
   negative_prompt: data.negative_prompt,
   generate_type: "MESH"
  },
  config
 );
}

// 标准版创建模型
export function createProModel (data, config = {}) {
 return fetch.post(
  "/lime/text2model/create_project",
  {
   prompt: data.prompt,
   negative_prompt: data.negative_prompt,
   generate_type: data.generate_type || "MESH",
   project_name: data.project_name || '',
   pbr_maps: data.pbr_maps || true,
   resolution: data.resolution || '1',
   art_style: data.art_style || ''

  },
  config
 );
}

export function getGenerateStatus (data, config = {}) {
 return fetch.get(
  "/lime/text2model/query_project_processing_progress/" + data ,
  config
 );
}

export function getProjectInfo (id, config = {}) {
 return fetch.get(
  "/lime/text2model/get_project/" + id,
  config
 );
}
export function getShareProjectInfo (id, config = {}) {
 console.log(id, config)
 return fetch.get(
  "/lime/text2model/get_project_unlimited/" + id,
  config
 );
}
// 删除项目
export function deleteProject (id, config = {}) {
 console.log(id, config)
 return fetch.delete(
  "/lime/text2model/delete/" + id,
  config
 );
}

export function downloadUrl (url, config = {}) {
 console.log(url, config)
 return fetch.get(
  url,
  config
 );
}
/* export function postPromptMessage (data, config) {
 return fetch.post(
  "http://lime-nvwa-pre.lightillusions.com/sse/push/views",
  data,
  {
   withCredentials: false,
   no_verify: false
  }
 );

} */

export function getMagicData (data, config) {
 return fetch.get(
  "/lime/text2model/magic/wand"
 );

}

//prompt输入调用
export function createPrompt (data, config) {
 return fetch.get(
  "/lime/text2model/get/view",
  data
 );

}

//prompt输入调用
export function reconModel (data, config) {
 return fetch.post(
  "/lime/text2model/generate/coarse/recon",
  data
 );

}

//生成模型
export function specificModel (data, config) {
 return fetch.post(
  "/lime/text2model/generate/fine/recon",
  data
 );

}

//中断sse连接
export function closeSSE (data, config) {
 return fetch.get(
  "/sse/close",
  {
   token: getCookie("access_token"),
   ...data
  }

 );

}

export function getOssMessage () {
 return fetch.post(
  "/oss/sts/authorize"
 );
}
/* 生成人物 */
export function generateHumanImg(data) {
  return fetch.post(
  "/image2human/generate/human/image",
  {
    
  }, {
     params: {
    name:'',
  prompt:'',
  style: 'default',
    ...data
  }
  }
 

 );
}
export function validateImg(data) {
 
    return fetch.get(
  "/image2human/image/review",
  {
     ossUrl: data.ossUrl
  }, {

  }
 

 );
}
export function generateHumanModel(data){
  
   return fetch.post(
  "/image2human/generate/task",
  {
  },
  {
     params: {
    name:'',
    ...data
  },
    alert_error: true
  }
 );
}
//轮训查询单个模型 
 export function queryModelImg23D({taskId, modelId}){
   return fetch.post(
  `/image2human/poll/model/status/${taskId}/${modelId}`

 );
}

//轮训任务详情


export function queryProjectImg23D(id){
  
   return fetch.post(
  `/image2human/poll/task/status/${id}`,

 );
}

export function getProjectImg23D(id){
  
   return fetch.post(
  `/image2human/get/task/${id}`,

 );
}
/* 点赞 */


export function likeModel({id, modelId}){
  
   return fetch.post(
  `/image2human/like/${id}/${modelId}/true`,

 );
}

export function getImage23DList({ currentPage = 0, pageSize = 10, asc, promptAsc, projectType }, config = {}){
  
   return fetch.post(
  `/image2human/list/tasks`,
  {

  },
  {
    params:  {
    currentPage,
    pageSize,
   desc: true
  },
   ...config,
   alert_error: true

  }
  


 );
}

//退出登录
export function exitLogin(){
  return fetch.post(
  `/lime/exit`,
 
 
 );
}


