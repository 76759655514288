

export default {
  preload: {
    inserted(el, binding) {
      const video = el.tagName.toLowerCase() === 'video' ? el : el.querySelector('video');
      if (video) {
        video.preload = binding.value || 'auto';
      }
    }
  }
/* 
 mounted(el, binding) {
  let img = new Image();
  img.src = binding.value;
  img.onload = function () {
   el.src = binding.value; 
  }

 }, */
}