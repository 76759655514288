const state = {
 isKeepAlive: true,
 modelList: [],
 modelLimitList: {}, //未登录列表页数据
 generate: {
  progress: 0,
  status: "QUEUED"
 },
 projectInfo: {}, //保存单个模型详情
 shareProjectInfo: {},
 queryDetail: {},
 isErrorList: false,
 initializing: true,
 showFourViews: false, //是否展示四视图
 noData: false,
 queueingCount: 0, //排队个数
 refineModel: {}, //精细模型参数
 createData: {},
 modelData: {},
 isOpen: false, //是否连接
 isTokenValid: false, //登录是否有效
 generatingList: [],
 userInfo: {}
}

export default state;