<template>
  <router-view v-slot="{ Component }">
    <keep-alive>
      <component :is="Component" v-if="isKeepAlive" :key="route.name" />
    </keep-alive>
    <component :is="Component" v-if="!isKeepAlive" :key="route.name" />
  </router-view>
</template>

<script setup>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

let store = useStore();
let route = useRoute();
let isKeepAlive = computed(() => {
  console.log(route.meta.keepAlive, 'route.meta.keepAlive')
  return route.meta.keepAlive
});
</script>
<style lang="less">
.header-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  transition: transform .2s;
  // transform: translate3d(0,-100%,0);

}
</style>
