export function getCookie(name) {
    if (!import.meta.env.SSR) {
     var arr, reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
  return   (arr =  document.cookie.match(reg)) ? decodeURIComponent(arr[2]) : "";

  }
  return;
};

export function setCookie(name, value, expires, path, domain, secure) {
  var cookie = name + '=' + encodeURIComponent(value);
  if (expires) {
    var date = new Date((new Date).setDate(new Date().getDate() + expires));
    cookie += ';expires=' + date.toGMTString();
  }
  if (path) cookie += ';path=' + path;
  if (domain) cookie += ';domain=' + domain;
  if (secure) cookie += ';secure';
  setCookiecontent(cookie)
}

export function delCookie(name) {
  var cookie = name + '=""';
  cookie += ';expires=-1';
  setCookiecontent(cookie)

}
function setCookiecontent(cookie) {
  if (!import.meta.env.SSR) {
    document.cookie = cookie;

  }
}

export function setStorage(key, value) {
  if (window && window.localStorage) {
    let localStorage = window.localStorage;
    localStorage && localStorage.setItem(key, value);
  }

}

export function getStorage(key) {
  let res = null;
  if (window && window.localStorage) {
    let localStorage = window.localStorage;
    localStorage && (res = localStorage.getItem(key));
  }

  return res;
}


export function debounce(func, delay) {
  let timer;

  return function () {
    clearTimeout(timer);

    timer = setTimeout(() => {
      func.apply(this, arguments);
    }, delay);
  };
}


export function throttle(func, delay) {
  let timer;
  let isThrottled = false;

  return function () {
    if (isThrottled) {
      return;
    }

    isThrottled = true;

    func.apply(this, arguments);

    timer = setTimeout(() => {
      isThrottled = false;
    }, delay);
  };
}

export function getUUId(len) {
  len = Math.min(len || 9, 9);
  let time = new Date().getTime();
  let str = (
    Math.random()
      .toString()
      .substr(2) -
    0 +
    time
  ).toString(36);
  str = shuffle(str.split(""), true).join("");
  return str.substr(0, len);
}

function shuffle(aArr, toUpperCase) {
  //随机打乱数组
  let iLength = aArr.length,
    i = iLength,
    mTemp,
    iRandom;
  while (i--) {
    if (i !== (iRandom = Math.floor(Math.random() * iLength))) {
      mTemp = aArr[i];
      if (toUpperCase) {
        mTemp = Math.random() > 0.5 ? (mTemp + "").toUpperCase() : mTemp;
      }
      aArr[i] = aArr[iRandom];
      aArr[iRandom] = mTemp;
    }
  }
  return aArr;
}

export function calculateEditDistance(a, b) {
  if (a.length === 0) return b.length
  if (b.length === 0) return a.length

  const matrix = []

  for (let i = 0; i <= b.length; i++) {
    matrix[i] = [i]
  }
  for (let i = 0; i <= a.length; i++) {
    matrix[0][i] = i
  }

  for (let i = 1; i <= b.length; i++) {
    for (let j = 1; j <= a.length; j++) {
      if (b.charAt(i - 1) === a.charAt(j - 1)) {
        //@ts-ignore
        matrix[i][j] = matrix[i - 1][j - 1]
      } else {
        //@ts-ignore
        matrix[i][j] = Math.min(
          //@ts-ignore
          matrix[i - 1][j - 1] + 1,
          //@ts-ignore
          Math.min(matrix[i][j - 1] + 1, matrix[i - 1][j] + 1),
        )
      }
    }
  }

  //@ts-ignore
  return matrix[b.length][a.length]
}

/**
 * 保存
 * @param  {Blob} blob     
 * @param  {String} filename 想要保存的文件名称
 */
function saveAs (blob, filename) {
  if (window.navigator.msSaveOrOpenBlob) {
    navigator.msSaveBlob(blob, filename);
  } else {
    const link = document.createElement('a');
    const body = document.querySelector('body');

    link.href = window.URL.createObjectURL(blob);
    link.download = filename;

    // fix Firefox
    link.style.display = 'none';
    body.appendChild(link);

    link.click();
    body.removeChild(link);

    window.URL.revokeObjectURL(link.href);
  }
}

/**
 * 下载
 * @param  {String} url 目标文件地址
 * @param  {String} filename 想要保存的文件名称
 */
export function download (url, filename) {
  getBlob(url).then(blob => {
    saveAs(blob, filename);
  });
}
/**
 * 获取 blob
 * @param  {String} url 目标文件地址
 * @return {Promise} 
 */
function getBlob (url) {
  return new Promise(resolve => {
    const xhr = new XMLHttpRequest();

    xhr.open('GET', url, true);
    xhr.responseType = 'blob';
    xhr.onload = () => {
      if (xhr.status === 200) {
        resolve(xhr.response);
      }
    };

    xhr.send();
  });
}