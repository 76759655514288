import * as api from "@api";
import { getMyModelList } from "@api/my/index.js"
// import { useRouter } from "@/routers";
// const router = useRouter();
import router from "@/routers";

const actions = {
  resetData({ commit }) {
    commit("updateCreateData", {});
  },

  async getModelList(
    { commit, dispatch },
    { type, currentPage, pageSize, asc, promptAsc, projectType, tab }
  ) {
    asc = promptAsc == void 0 ? (asc == void 0 ? false : asc) : void 0;

   
    let mapping = {
      doll: api['getImage23DList'],
      models: getMyModelList,
      detail:  api['getList'],
    }
    try{
           let result  = await mapping[(tab || 'models')]({ currentPage, pageSize, asc, promptAsc, projectType })
      console.log('reuskt,,,',result);
    if (result && (result.status == 0 || result.flag) && ['models','detail'].includes(tab)) {
      //这里找列表中有生成中的数据
      let geneModels = await dispatch("findGenerateModels", result.data);

      commit("updateList", {
        type,
        data: result.data,
      });
      commit("updateGeneratingList", {
        type,
        data: geneModels,
      });
      commit("setInitializing", false);
      commit("setNoData", result.data.length == 0);
    }else if(result && result.flag) {
      let { records
 = [] } = result.data|| {};

      //image23d
        let geneModels = await dispatch("findDollGenerateModels", records);
        console.log(geneModels,'geneModels')
      commit("updateList", {
        type,
        data: records
      });
      commit("updateGeneratingList", {
        type,
        data: geneModels,
      });
      commit("setInitializing", false);
      commit("setNoData", records.length == 0);

    }

    }catch(error){
            commit("setInitializing", false);
    }

  },
  findGenerateModels({}, data = []) {
    let modelIds = data.reduce((pre, cur) => {
      if (["MAKING", "QUEUED"].includes(cur.status)) {
        pre.push(cur.id);
      }
      return pre;
    }, []);
    return modelIds;
  },
  findDollGenerateModels({}, data = []) {
      console.log('findDollGenerateModels',data);
    let modelIds = data.reduce((pre, cur) => {
      let waitingIds = [];
      cur.previewInfo.forEach(v=> {
        if(v.status === 'WAITING' || v.status === 'GENERATING'){
          waitingIds.push({
            taskId: cur.id,
            modelId: v.modelId
          })
        }
      }
      );

      pre.push(...waitingIds);
      return pre;
    }, []);
    return modelIds;
  },
  //未登录获取的数据
  async getModelLimitList(
    { commit },
    { type, currentPage, pageSize, asc, promptAsc, userToken }
  ) {
    try {
      let result = await api
        .getLimitList(
          { currentPage, pageSize, asc, promptAsc, userToken },
          { withCredentials: false }
        )
        .catch((e) => console.log(e));
      if (result && result.status == 0) {
        commit("updateLimitList", {
          type,
          data: result.data?.list || [],
        });
        commit("setInitializing", false);
        commit("setQueueingCount", result.data?.queueingCount || 0);
        commit("setNoData", result.data.length == 0);
      } else {
        commit("setInitializing", false);
      }
    } catch (e) {
      console.log("error", e);
      commit("updateError", true);
    }
  },
  async searchModelList({ commit }, data) {
    let result = await api
      .searchList({ keyword: data.keyword }, { withCredentials: false })
      .catch((e) => console.log(e));
    if (result && result.code == 200) {
 /*      if (data.origin == "mylist") {
        commit("updateList", {
          type: "init",
          data: result.data,
        });
      } else {
        commit("updateLimitList", {
          type: "init",
          data: result.data,
        });
      } */
    }
  },

  async getGenerateStatus({ commit }, id) {
    try {
      let result = await api.getGenerateStatus(id);
      if (result.status == 0) {
        commit("updateGenerateStatus", result.data);
      } else {
        commit("updateGenerateStatus", { status: "FAILED", progress: 0 });
      }
    } catch (e) {
      commit("updateGenerateStatus", { status: "FAILED", progress: 0 });
    }
  },

  async getProjectInfo({ commit }, {id,type, modelId}) {
    console.log("server", api);
    let mapping = {
      // "image23D": "getProjectImg23D",
      "image23D": "queryModelImg23D"
    }
    try {
      let url = mapping[type]|| "getProjectInfo"
      let data = type == 'image23D' ? {taskId: id, modelId} :id
      let result = await api[url](data);
      if (result.status == 0) {
        commit("updateProjectInfo", result.data);
        return result.data;
      } else if(result.flag){
        commit("updateProjectInfo", result.data);
        return result.data;
      
      }else {
        console.log("获取详细信息失败", res);
      }
    } catch {
      console.log("获取详情信息失败");
    }
  },
  /*    return axios.get("http://60.205.156.208:8100/lime/text2model/get_project_unlimited/7f385401-8f09-4bae-a941-32a22d649b5b").then(res=>{
        console.log("res","3333333",res.data);
        return res.data;
      },(error)=>{
        console.log("error.....",error)
      }) */
  async getShareProjectInfo({ commit }, {id,modelId, type}) {
    console.log("server", api);
    try {
      let mapping = {
        'image23D': 'queryModelImg23D'
      }
      let data = type == 'image23D' ? {taskId:id, modelId}:id
      let result = await api[mapping[type] || 'getShareProjectInfo'](data, { no_verify: false });
      if (result.status == 0 || result.flag) {
        commit("updateShareProjectInfo", result.data);
        commit("updateProjectInfo", result.data);
        return result.data;
      } else {
        console.log("获取详细信息失败", res);
      }
    } catch {
      console.log("获取详情信息失败");
    }
  },

  async setCookieData({}, data) {
    let { name, value, expires } = data;
    var cookie = name + "=" + encodeURIComponent(value);
    if (expires) {
      var date = new Date(new Date().setDate(new Date().getDate() + expires));
      cookie += ";expires=" + date.toGMTString();
    }
    if (path) cookie += ";path=" + path;
    if (domain) cookie += ";domain=" + domain;
    if (secure) cookie += ";secure";
    commit("SETCOOKIE", cookie);
  },
  async getRefineModel({ commit, state }, { action, ...rest }) {
    //区分是新建还是我的
    console.log("dndjfndjfnvjknevjenvj");
    let data = {};
    if (action == "create") {
      data = { ...state.createData };
    } else {
      data = { ...state.projectInfo };
    }
    data = Object.assign({}, rest, data);
    return api
      .specificModel({
        imageUrl: data.imageUrl || data.cover_url,
        prompt: data.prompt,
        coarseModelUrl: data.coarseModelUrl || data.coarse_model_url,
        negativePrompt: "",
        projectName: "",
        id: data.id,
      })
      .then((res) => {
        console.log(res);
        let { data = {} } = res;
        let id = data?.id || "";
        //清空projectInfo
        // commit("updateProjectInfo", {mode: 'refine'});
        let createData = state.createData;
        commit("updateCreateData", {
          ...createData,
          id,
          type: "refine",
          action: "create",
          status: "",
        }); //这里跟查列表模型类似
        return res;
        // 这里关闭四视图，回到首页，展示详情页弹框
        // emits("showDialog", { ...data,prompt, origin: 'mylist' });
      })
      .finally(() => {
        commit("setQueueingCount", state.queueingCount + 1);
      });
  },
  // login out the system after user click the loginOut button
  goNewRoute({}, {path, data}) {
    router.push({
      path,
      query: data
    });
  },
};

export default actions;
