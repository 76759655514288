import { getCookie } from "../utils";
import axios from "axios";
import store from "@/stores"
const service = axios.create({
 // baseURL: baseURL,
 timeout: 5000 * 10
})
service.interceptors.request.use(function (config) {
 config.baseURL = "http://text2model-server.lightillusions.com";
 config.baseURL = import.meta.env["VITE_baseUrl"];
 config.headers["Content-Type"] = "application/json";
 // 匿名校验
/*  config && config.no_verify !== false && (config.headers["Authorization"] =  getCookie("access_token") || config.headers); */
if(config && config?.no_verify !== false){
  config.headers["Authorization"] = getCookie("access_token")
}else {
  config.headers = config.headers
}

 config.timeout = 30 * 1000;
 config.withCredentials = config.withCredentials == undefined ? true : config.withCredentials;
 return config;
}, function (error) {
 return Promise.reject(error);
});
service.interceptors.response.use((response) => {
    const res = response.data
  if (res.code === 200) {
  return res
 } else {
  showError(response)
  return Promise.reject(res)
 }

}, (error) => {

  console.log(error) // for debug
 const badMessage = error.message || error
 const code = parseInt(badMessage.toString().replace('Error: Request failed with status code ', ''))
 showError({ code, message: badMessage })
 return Promise.reject(error)

})
// 错误处理
function showError (res) {
  let {code, config ={}, data={}} = res
 // token过期，清除本地数据，并跳转至登录页面
 if (code === 1000 || data.code === 1000) {
  // to re-login
  // const store = useStore();
  console.log(store, 'store..')
  let data = { date: new Date().getTime() };
  store && store.dispatch('goNewRoute', {path:'/', data});
  store && store.commit('setIsTokenValid', false);
  console.log('跳转到登录页面')
 } else {
  if(config.alert_error) {
    NvUI.alert({
      type: 'error',
      text: data.msg || "接口请求失败"
    })
  }

  console.log(data.msg)
  /* ElMessage({
   message: error.msg || error.message || '服务异常',
   type: 'error',
   duration: 3 * 1000
  }) */
 }

}

class Fetch {
 constructor() {

 }
 async request (url, method = "get", data = {}, config = {}) {
  if (method == "get") {
   try {
    return await this.get(url, data, config);
   } catch (error) {
    return Promise.reject(error);
   }
  } else if (method == "post") {
   try {
    return await this.post(url, data, config);
   } catch (error) {
    return await Promise.reject(error);
   }
  } else if (method == "delete") {
   try {
    return await this.delete(url, data, config);
   } catch (error) {
    return await Promise.reject(error);
   }

  } else {
   return Promise.reject("request type must be get or post.");
  }
 }

 async get (url, data = {}, config = {}) {
  config.params = data;
  try {
   let response = await service.get(url, config);
   /* console.log("response", response)
   if (response.status == 200) {
    console.log("$message", this.$message, NvUI)
    if (response.data && response.data.code && response.data.code == 200) {
     return response.data;
    } else {
     if (config.alert_error) {
      NvUI.alert({ type: 'error', text: response.data?.message || '' });
     } else {
      return Promise.reject("response.data?.message")
     }
    }
   } else {
    return Promise.reject("server response error.")
   } */
   return response
  } catch (error) {
   return Promise.reject(error)
  }

 }
 async post (url, data = {}, config = {}) {
  try {
   let response = await service.post(url, data, config);
   /* if (response.status == 200) {
    return response.data;
    if (response.data && response.data.code && response.data.code == 200) {
     return response.data;
    } else {
     if (config.alert_error) {
      NvUI.alert({ type: 'error', text: response.data?.message || '' });
     } else {
      return Promise.reject(response)
     }
    }
   } else {
    return Promise.reject("server response error.")
   } */
   return response
  } catch (error) {
   return Promise.reject(error)
  }
 }
 async delete (url, data = {}, config = {}) {
  try {
   let response = await service.delete(url, data, config);
   /* if (response.status == 200) {
    console.log("$message", this.$message, NvUI)
    if (response.data && response.data.code && response.data.code == 200) {
     return response.data;
    } else {
     if (config.alert_error) {
      NvUI.alert({ type: 'error', text: response.data?.message || '' });
     } else {
      return Promise.reject("response.data?.message")
     }
    }
   } else {
    return Promise.reject("server response error.")
   } */
   return response
  } catch (error) {
   return Promise.reject(error)
  }

 }
}

export default new Fetch;