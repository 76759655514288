
import fetch from "../fetch";

export function getMyModelList (data, config = {}) {
 return fetch.post(
  "/mine/page/model/list",
  {
  },
  {

    params: data
  },{
        ...config,
  }
 );
}