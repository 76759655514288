// import {createApp} from "vue";
import { createSSRApp } from "vue";
import App from "./app.vue";
import store from "./stores";
import router from "./routers";
import Components from '@/components/index.js'
import {  lazy, preload,videoPreload } from './directives/index.js'
// import { createRouter } from "./routers";

if (import.meta.env.SSR === false) {
 import('../src/styles/index.css');
 import("./assets/fonts/icon/iconfont.js");
 import("./assets/css/font.css")
 import("./assets/css/symbol.css");
 window.NvUI = {};
 // async
 // import globalUI from '@/components'

}





export function createApp () {
 const app = createSSRApp(App);
 //   const router = createRouter();
 //   const { store } = createStore();

 /*  router.beforeResolve(async (to, from) => {
      let toMatchedComponents = getMatchedComponents(to.matched);
      let fromMatchedComponents = getMatchedComponents(from.matched);
      // 优化过滤
      let isSameCompoent = false;
      let components = toMatchedComponents.filter((compnent, index) => {
          return isSameCompoent || (isSameCompoent = fromMatchedComponents[index] !== compnent);
      });
 
      console.log("[components]", components, toMatchedComponents, fromMatchedComponents);
 
      // 需要执行async的组件
      components.length &&
          (await Promise.allSettled(
              components.map((component) => {
                  // @ts-ignore
                  if (component.asyncData) {
                      // @ts-ignore
                      return component.asyncData({ store, route: to });
                  }
              })
          ));
  }); */

 // console.log(Components, 'Components')
 app.use(store);
 app.use(router);
 app.use(Components)
 app.directive('lazy', lazy);
 app.directive('preload', preload);
 app.directive('videopreload',videoPreload)
 /*  import('@/components').then(module => {
   module && app.use(module.default);
 
 
 
  }) */
 /*  if (!import.meta.env.SSR) {
 
   import('@/components/popover').then(module => {
 
    module && app.use(module.default)
 
 
   })
 
  } */
 return { app, router, store };
}


/* app.use(store);
app.use(router);

app.mount("#app"); */