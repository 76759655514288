let mutations = {
 updateKeepAlive (state, payload) {
  state.isKeepAlive = payload;
 },
 updateList (state, { type, data }) {
 
  if (type == "init") {
   state.modelList = data;
  } else {
   state.modelList.push(...data);
  }
   console.log('updateList',data,state.modelList);
 },
 updateModeById (state, { id, status, ...data }) {
  id = id ? id : data.taskId;
  let findIndex = state.modelList.findIndex(v => v.id == id);
  if (findIndex > -1 && status) {
    if(data.type == 'image23D'){
      let previews = state.modelList[findIndex].previewInfo||[];
     let idx =  previews.findIndex(v=>v.modelId == data.modelId);
     idx>-1 && (previews.splice(idx,1,{ ...previews[idx], status, ...data}));
    }else {
      state.modelList[findIndex] = {...state.modelList[findIndex], status, ...data}
    }
  
  }
  
 },
 updateLimitList (state, { type, data }) {
  if (type == "init") {
   state.modelLimitList = data;
  } else {
   state.modelLimitList.push(...data);
  }
 },
 updateGenerateStatus (state, payload) {
  state.generate = payload;
 },
 updateProjectInfo (state, payload) {
  state.projectInfo = payload;
 },
 setShowFourViews (state, payload) {
  state.showFourViews = payload;
 },
 updateShareProjectInfo (state, payload) {
  state.shareProjectInfo = payload;

 },
 setNoData (state, payload) {
  state.noData = payload;
 },
 setQueryDetail (state, payload) {
  state.queryDetail = payload;
 },
 updateError (state, payload) {
  state.isErrorList = payload
 },
 setInitializing (state, payload) {
  state.initializing = payload
 },
 setQueueingCount (state, payload) {
  state.queueingCount = payload

 },
 setFineModel (state, payload) {
  state.refineModel = payload

 },
 setIsOpen (state, payload) {
  state.isOpen = payload
 },
 setIsTokenValid (state, payload) {
  state.isTokenValid = payload
 },
 setUserInfo(state, payload) {
  state.userInfo =  payload;

 },
 updateCreateData (state, payload) {
  state.createData = payload;
 },
 deleteGeneratingID (state, {type, id,taskId,modelId}) {
  let findIndex = -1
  if(type){
  findIndex = state.generatingList.findIndex(v => v.modelId == modelId);
  }else{
    
  findIndex = state.generatingList.findIndex(v => v == id);

  }
  if (findIndex > -1) {
   state.generatingList.splice(findIndex,1)
  }
 },
 updateGeneratingList (state, { type, data }) {
  if (type == "init") {
   state.generatingList = data;
  } else {
   state.generatingList.push(...data);
  }
 },

}

export default mutations;