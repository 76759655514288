import Home from "../views/login/index.vue";

// const List = () => import("../views/list/index.vue");
const List = () => import("../views/list/index.vue");
const Detail = () => import("../views/detail/index.vue");
const Generate = () => import("../views/generate/index.vue");
const Create = () => import("../views/create/index.vue");
const UnperList = () => import("../views/unpermission/index.vue");
const Privacy = () => import("../views/legal/index.vue");
const MySetting = () => import("../views/my/index.vue");

const Protocol = () => import("../views/legal/protocol.vue");

let routes = [
 {
  path: "/login",
  name: "home",
  component: Home,
  alias: "/login"
 },
 {
  path: "/",
  name: "list",
  component: List,
  alias: "/list",
  meta: {
   keepAlive: true,
   requiresAuth: true


  }
 },
 {
  path: "/mylist",
  name: "mylist",
  component: MySetting,
  meta: {
   keepAlive: true,
   requiresAuth: true
  }
 },
 {
  path: "/share",
  name: "share",
  component: UnperList,
  alias: "/share/:id",
  meta: {
   keepAlive: true,
   requiresAuth: false
  }
 },
 {
  path: "/detail/:id",
  name: "detail",
  component: Detail,
  meta: { requiresAuth: false }
 },
 {
  path: "/generate/:id",
  name: "generate",
  component: Generate
 },

 {
  path: "/create",
  name: "create",
  component: Create
 },
 {
  path: "/protocol",
  name: "protocol",
  component: Protocol
 },
 {
  path: "/privacy",
  name: "privacy",
  component: Privacy
 },
   {
    path: '/:catchAll(.*)',
    redirect: { name: 'list' }
  },
];

export default routes