<template>
  <!-- vue动画：从上滑入且淡出 -->
  <Transition name="down">
    <!-- style 绑定的是样式 -->
    <div class="message" :style="style[type]" v-show="visible">
      <!-- 不同提示图标会变 -->
      <i class="text23d-icon" :class="[style[type].icon, 'status-'+type]"></i>
      <span class="text">{{text}}</span>
    </div>
  </Transition>
</template>

<script>
import { onMounted, ref } from 'vue'

export default {
  name: 'Message',
  props: {
    text: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      // warn 警告  error 错误  success 成功
      default: 'warn'
    }
  },
  setup () {
    // 定义一个对象，包含三种情况的样式，对象key就是类型字符串
    const style = {
      warn: {
        icon: 'icon-warning',
        color: '#E6A23C',
        backgroundColor: 'rgb(253, 246, 236)',
        borderColor: 'rgb(250, 236, 216)'
      },
      error: {
        icon: 'icon-tanhao',
        color: '#FFF',
      },
      success: {
        icon: 'icon-duigou',
        color: '#FFFFFF',
        /* backgroundColor: 'rgb(240, 249, 235)',
        borderColor: 'rgb(225, 243, 216)' */
      }
    }
    // 定义一个数据控制显示隐藏，默认是隐藏，组件挂载完毕显示
    const visible = ref(false)
    onMounted(() => { // 需调用钩子函数，等dom渲染完成后，再进行赋值，如果在setup中直接赋值，则会被直接渲染成true
      visible.value = true
    })
    return { style, visible }
  }
}
</script>

<style scoped lang="less">
.down {
  &-enter {
    &-from {
      transform: translate3d(0,-50% ,0);
      opacity: 0;
    }
    &-active {
      transition: all 0.5s;
    }
    &-to {
      transform: none;
      opacity: 1;
    }
  }
}
.message {
  height: 40px;
  position: fixed;
  z-index: 9999;
  left: 50%;
  /* margin-left: -150px; */
  // top: 25px;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  line-height: 40px;
  padding: 0 25px;

  background: rgba(149,149,149,0.25);
  border-radius: 20px;
  color: #999;
  backdrop-filter: blur(10px);
 
  i {
    margin-right: 4px;
    vertical-align: middle;
    background: #34A853;
    border-radius: 50%;
    line-height: 40px;
    &.status-error {
     background: #EA4335;
    }

  }
  .text {
    vertical-align: middle;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 40px;
    
  }
}
</style>
