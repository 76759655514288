<template>
  <div class="light-popover" ref='popover'>

    <!-- 不可以使用stop 会阻止用户的操作 -->
    <transition name='slide-up'>
      <div v-if="init" ref='content' v-show='show' class="light-popover__content"
        :class="[options.direction, popoverClass]" :style="{ // 这里就是控制定位的关键
        top: top + 'px',
        left: left + 'px'
      }">
        <div class="cc-popover__box">
          <slot name="content"> 请输入内容</slot>
          <div class="poper__arrow" :style="{ left: left + 'px' }"></div>
        </div>
      </div>
    </transition>
    <!-- // 展示的内容 -->
    <slot></slot>
  </div>
</template>
<script>

import { on, off } from "../../utils/events"
import { debounce } from "../../utils/index.js"
import { getPopoverPosition } from "./utils"

export default {
  name: "popover",
  props: {
    trigger: {
      type: String,
      default: "hover",
      validator: value => ["click", "hover"].indexOf(value) > -1
    },
    placement: {
      // 方位我们定位的范围是, 每个方向都有'开始','中间','结束'三种情况
      type: String,
      default: "right-middle",
      validator (value) {
        let dator = /^(top|bottom|left|right)(-start|-end|-middle)?$/g.test(
          value
        );
        return dator;
      }
    },
    isHiddenPopover: {
      type: Boolean,
      default: false
    },
    popoverClass: String
  },
  data () {
    return {
      time: "",
      top: 0,
      left: 0,
      init: false,
      show: false,
      options: {}
    }

  },
  watch: {

    // 我们会监控v-if的情况, 第一次渲染的时候才做这里的操作, 而且只执行一次
    init () {
      this.$nextTick(() => {
        let trigger = this.trigger,
          dom = this.$refs.content,
          content = this.$refs.content;
        // 这里有人会有疑问, 这什么鬼写法
        // 这里是因为append操作属于剪切, 所以不会出现两个元素
        // 其实这个元素出现之后就一直存在与页面上了, 除非销毁本组件
        // 组件销毁的时候, 我们会document.body.removeChild(content);
        document.body.appendChild(dom);
        if (trigger === "hover") {
          on(content, "mouseenter", this.handleMouseEnter);
          on(content, "mouseleave", this.handleMouseLeave);
        }
      });
    },
    // 这个才是每次显示隐藏都会触发的方法
    show () {
      // 判断只有显示提示框的时候才回去计算位置
      if (this.show) {
        this.$nextTick(() => {
          let { popover, content } = this.$refs,
            { left, top, options } = getPopoverPosition(
              popover,
              content,
              this.placement,
              null
            );
          console.log(left, top);
          // 有了坐标, 就可以很开心的定位了
          this.left = left;
          this.top = top;
          // 这个配置是决定 '小三角' 的位置的
          this.options = options;
        });
      }
    }
  },
  methods: {
    // 更新位置
    updatePosition () {
      console.log("updatePosition")
      if (this.show) {
        this.$nextTick(() => {
          let { popover, content } = this.$refs,
            { left, top, options } = getPopoverPosition(
              popover,
              content,
              this.placement,
              null
            );
          console.log(left, top);
          this.left = left;
          this.top = top;
          // 这个配置是决定 '小三角' 的位置的
          this.options = options;
        });

      }

    },
    handleMouseEnter () {
      clearTimeout(this.time);
      this.init = true;
      this.show = true;

    },
    handleMouseLeave () {
      clearTimeout(this.time);
      this.time = setTimeout(() => {
        this.show = false;
      }, 200);

    },
    handlClick () {
      // 不管怎么样只要触发一次, 这个值就会把v-if永远置成true;
      this.init = true;
      // 在他本身被css属性隐藏的时候
      if (this.$refs.content && this.$refs.content.style.display === "none") {
        // 必须这样强制写, 
        // 否则与之后的代码配合时, 有bug无法消失
        this.$refs.content.style.display = "block";
        this.show = true;
      } else {
        // 除了第一次之外, 之后都只是变换这个this.show的'真假'
        this.show = !this.show;
      }
      // 不要监听body, 因为可能height不是100%;
      // 这个document其实也可以由用户指定
      // 放入让popover消失的函数, 这样方便之后的移除事件操作
      this.show && document.addEventListener("click", this.close);

    },
    close (e) {
      // 肯定要判断事件源到底是不是咱们的popover组件
      /*
      事件源是popover组件，
      */
      console.log(this.isPopover(e), "close");
      if (this.isPopover(e)) {
        this.show = false;
        // 点击完就可以移除了, 下次操作再绑定就可以
        // 因为如果往document绑定太多事件, 会非常卡, 非常卡
        document.removeEventListener("click", this.close);
      }
    },
    isPopover (e) {
      let dom = e.target,
        popover = this.$refs.popover,
        content = this.$refs.content;
      // 1: 点击popover包裹的元素, 关闭popover
      // 2: 点击popover内容区元素, 不关闭popover
      // console.log(popover.contains(dom) , content.contains(dom))
      console.log("popover", popover, popover && popover.contains(dom));
      console.log("content", content, content && content.contains(dom));
      // console.log(this.isHiddenPopover, content, dom,content.contains(dom))
      let bool = false;
      //两个都不存在，直接隐藏popover 
      if (!popover && !content) {
        this.destroyCom();
        return true;
      }
      return !(popover.contains(dom) || this.isHiddenPopover && content.contains(dom) ? !content.contains(dom) : content.contains(dom));
      // this.isHiddenPopover ? (popover.contains(dom) && content.contains(dom)) : !(popover.contains(dom) || content.contains(dom));
    },
    destroyCom () {
      let { popover, content } = this.$refs;
      off(content, "mouseleave", this.handleMouseLeave);
      off(popover, "mouseleave", this.handleMouseLeave);
      off(content, "mouseenter", this.handleMouseEnter);
      off(popover, "mouseenter", this.handleMouseEnter);
      off(document, "click", this.close);
      if (document.body.contains(content)) {
        document.body.removeChild(content);
      }
    }

  },
  mounted () {
    // getPopoverPosition()

    this.$nextTick(() => {
      // 获取到当前用户定义的事件类型
      let trigger = this.trigger,
        // 本次选择操作dom
        popover = this.$refs.popover;
      if (trigger === "hover") {
        // hover当然要监听 进入与离开的事件拉
        on(popover, "mouseenter", this.handleMouseEnter);
        on(popover, "mouseleave", this.handleMouseLeave);
      } else if (trigger === "click") {

        on(popover, "click", this.handlClick);
      }
      console.log("start", this.updatePosition)
      on(window, 'resize', debounce(this.updatePosition, 200));
    });
  },
  beforeDestroy () {
    let { popover, content } = this.$refs;
    off(content, "mouseleave", this.handleMouseLeave);
    off(popover, "mouseleave", this.handleMouseLeave);
    off(content, "mouseenter", this.handleMouseEnter);
    off(popover, "mouseenter", this.handleMouseEnter);
    off(document, "click", this.close);
    off(window, "resize", this.updatePosition);
    if (document.body.contains(content)) {
      document.body.removeChild(content);
    }
  }
}

</script>
<style lang="less">
.light-popover__content {
  position: absolute;
  border-radius: 4px;
  padding: 4px 8px;
  font-size: 12px;
  line-height: 18px;
  min-width: 10px;
  max-width: 400px;
  word-wrap: break-word;




}
</style>